import React from "react"

import Layout from "../../components/Layout"
import Iframe from "../../components/Iframe"

const JobApply = ({ location }) => {
  return (
    <Layout>
      <Iframe
        id={process.env.GATSBY_APPLY_FORM_ID}
        jobPosition={location.search}
      />
    </Layout>
  )
}

export default JobApply
