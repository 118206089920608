import React from "react"
import styled from "styled-components"

const Iframe = ({ id, jobPosition }) => {
  return id ? (
    <Conatainer>
      <StyledIframe
        id={`JotFormIFrame-${id}`}
        title="Form"
        onLoad={() => {
          window.parent.scrollTo(0, 0)
        }}
        allowtransparency="true"
        allowFullScreen={true}
        allow="geolocation; microphone; camera"
        src={`https://form.jotform.com/${id}?jobPosition=${jobPosition}`}
        // To populate fields:
        // inspect the field you want to populate with developer tool
        // take the part after the underscore "_" in the name propertie and put the value you want
        // ex name="g5_jobPosition" => url?jobPosition=valueYouWant?anotherField=anotherValue
        frameBorder="0"
        scrolling="yes"
      />
      <StyledOverlay />
    </Conatainer>
  ) : (
    <div>
      Your form is invalid please get in touch with the development team by
      other means
    </div>
  )
}

const Conatainer = styled.div`
  position: relative;
  height: 100vh;
`
const StyledIframe = styled.iframe`
  min-width: 100%;
  height: 100vh;
  border: none;
`
const StyledOverlay = styled.div`
  position: absolute;
  height: 65px;
  width: 100%;
  background-color: white;
  bottom: -1px;
`

export default Iframe
